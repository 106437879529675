import React from "react";
import Link from 'next/link';
import LinksProvider from '../../../services/http/LinksProvider';
import {UserAvatar} from "../../user-avatar/UserAvatar";
import css from './ProfileButton.module.scss';
import {PIC_FORM} from "@/shared/user-avatar/Enums";

const ProfileButton = ({principal}) => {
  return (<Link href={LinksProvider.ROUTES.ACCOUNT.DASHBOARD}>
      <a>
        <span>
          <button className={css.account__btn}>

            <UserAvatar profilePicture={principal.profile_image_link}
                        firstName={principal.first_name}
                        lastName={principal.last_name}
                        picForm={PIC_FORM.SQUARED}/>

            {/*<span className={`${css.account__img}`}>*/}
            {/*  {principal.profile_image_link && (<img src={principal.profile_image_link}/>)}*/}
            {/*  {!principal.profile_image_link && (principal.first_name.charAt(0).toUpperCase()*/}
            {/*      + principal.last_name.charAt(0).toUpperCase())}*/}
            {/*</span>*/}
              <span className={css.account__content}>{principal.first_name}</span>
            </button>
        </span>
      </a>
      </Link>

  );
};

export default ProfileButton;
