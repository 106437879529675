import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import css from './_navbrand.module.scss';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';

const Navbrand = (props) => {
  return (
    <Link href="/">
      <a className={`${css.nav__brand} ${props.styleOverwrite}`}>
        <Image
          src={props.imgSrc}
          width={120}
          height={isMobile ? 85 : 120}
          style={isMobile ? { padding: "10px 10px 10px 0" } : {}}
          alt={props.imgDesc}
        />
      </a>
    </Link>
  );
};

Navbrand.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgDesc: PropTypes.string
};

Navbrand.defaultProps = {
  imgSrc: "/static/images/logos/logo.webp",
  imgDesc: "Img Desc for accessibility  purpose"
};

export default Navbrand;
