import React from 'react';
import StringService from "../../services/utils/StringService";
import css from "./UserAvatar.module.scss";
import {PIC_FORM} from './Enums';
import Image from 'next/image';


export const UserAvatar = ({customClass, profilePicture, lastName, firstName, profile, picForm = PIC_FORM.ROUND}) => {
    return (
        <span className={`${css.user_avatar} ${customClass || ''}`}>


      <React.Fragment>
        {profilePicture && (
            <div className={`${css.profile_pic} ${picForm === PIC_FORM.SQUARED ? css.profile_pic_squared : css.profile_pic_round}`}
                 style={{backgroundImage: `url('${profilePicture}')`}}
            />
        )}
        {!profilePicture && (StringService.getInitials(firstName, lastName))}

        {profile && !!(profile.has_fb_account ^ profile.has_google_account) && (
          <span className={css.social_tag}>
            {profile.has_google_account && (
                <Image src={'/static/images/pictos/icon-google.svg'} alt='google-profile-picture' width={30} height={30}/>
            )}
            {profile.has_fb_account && (
              <Image src={'/static/images/pictos/icon-social-facebook.svg'} alt='facebook-profile-picture' width={30} height={30}/>
            )}
          </span>
        )}
      </React.Fragment>

    </span>);
};
