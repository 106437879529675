export const PublicationTemplate = {
  PRIMARY: 0,
  SECONDARY: 1
};

export const PublicationCategory = {
  OTHER: 0,
  NEWS: 1,
  FAVORITES: 2,
  HONEYMOON: 3,
  TABLEWARE: 4,
  BEAUTY: 5,
  COSTUMES: 6,
  DECORATION: 7,
  JEWELERY: 8,
  HOUSE_LINENS: 9,
  ACCESSORIES_FASHION: 10,
  //FASHION: 11, //deprecated
  ORGANIZATION: 12
};

export const PublicationCategoryReverse = {
  "autres": 0,
  "actualites": 1,
  "marques-coup-de-coeur": 2,
  "voyage-de-noces": 3,
  "art-de-la-table": 4,
  "beaute": 5,
  "costumes": 6,
  "deco": 7,
  "joaillerie": 8,
  "linge-de-maison": 9,
  "mode-collections-capsules-lingerie-et-accessoires": 10,
  "organisation": 12,
}


export const PublicationStatus = {
  INACTIVE: 0,
  ACTIVE: 1
};