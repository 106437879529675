/**
 * StringService
 * @module Services/utils/StringService
 * @description Offers utility functions to handle strings
 */


const self = {};


/**
 * Gets the initials of a person
 * @author Hassen Charef
 * @alias getInitials
 * @memberof module:Services/utils/StringService
 * @param   {string} firstName The first name of the person
 * @param   {string} lastName The first name of the person
 * @returns {string} The initials of the person
 */
self.getInitials = (firstName, lastName) => {
  if (!firstName || !lastName) {
    return "Anon";
  }
  return firstName.substr(0, 1).toUpperCase() + lastName.substr(0, 1).toUpperCase();
};

/**
 * Capitalize first letter of string
 * @author Seif Khiari
 * @alias capitalizeFirst
 * @memberof module:Services/utils/StringService
 * @param   {string} str string to capitalize
 * @returns {string} The capitalized string
 */
self.capitalizeFirst = (str) => {
  if (!str) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1);
};



/**
 * Removes underscores from a string and capitalizes each word
 * @author Amine Elouaer
 * @alias removeUnderscoresAndCapitalize
 * @memberof module:Services/utils/StringService
 * @param   {string} str String containing underscores
 * @returns {string} String with underscores replaced by spaces and each word capitalized
 */

self.removeUnderscoresAndCapitalize = (str) => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
export default self;
