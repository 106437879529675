import React from "react";
import Popup from "reactjs-popup";
import css from './Tooltip.module.scss';


const Tooltip = (props) => {

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (<div className={`${css.tooltip} ${props.customClass ? props.customClass : ''}`}
               onClick={stopPropagation}
  >
    <Popup
      trigger={props.triggerComponent || (<button type="button"
                                                  className="tooltip__trigger"
      >
        <img
          srcSet={props.customImageUrl ? props.customImageUrl : '/static/images/pictos/icon-question-mark-black.svg'}/>
      </button>)}
      position={props.position ? props.position : 'right bottom'}
      closeOnDocumentClick
      repositionOnResize
      keepTooltipInside={true}
      on={props.trigger ? props.trigger : 'hover'}
    >
      <React.Fragment>
        {props.children && (
          <div className={`${"tooltip_body"} ${props.customClass ? props.customClass : ''}`}>{props.children}</div>
        )}
        {props.contentWithHtml && (
          <div className="tooltip__body"
               dangerouslySetInnerHTML={{__html: props.contentWithHtml}}
          />
        )}
      </React.Fragment>

    </Popup>
  </div>);
};

export default Tooltip;

