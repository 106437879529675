import Link from "next/link";
import React, { useEffect, useState } from "react";
import { withTranslation } from "../../../i18n";
import LinksProvider from '../../../services/http/LinksProvider';
import ManageCoookiesModal from "../../cookies/ManageCookieModal/ManageCookieModal";
import PublicationsService from "../../../services/domain/PublicationsService";
import { PublicationCategory } from "@/models/Publication";
import css from './FooterSitemap.module.scss';
import Image from "next/image";


const namespacesRequired = ['footer'];

const FooterSitemap = ({t}) => {
  const [showManageCookieModal, setShowManageCookieModal] = useState(false);
  // const [isMarketingCookiesActive, setIsMarketingCookiesActive] = useState(null);

  useEffect(() => {
    // UNCOMMENT THIS CODE FOR HIDING SOCIAL NETWORK ICON
    // setIsMarketingCookiesActive(CookiesService.isMarketingCookieActive());
    // if (typeof globalThis !== 'undefined') {
    //   globalThis.window.addEventListener(CookiesService.MARKETING__COOKIE_TOOGLED_EVENT_NAME, () => {
    //     setIsMarketingCookiesActive(CookiesService.isMarketingCookieActive());
    //   }, false);
    // }
  }, []);

  return (
    <div className={css.footer}>
      {showManageCookieModal && (
        <ManageCoookiesModal
          visible={showManageCookieModal}
          onHide={() => {
            setShowManageCookieModal(false);
          }}
          onSubmit={() => {
            setShowManageCookieModal(false);
          }}
        />
      )}

      <ul className={css.footer_sitemap}>
        <li>
          <Link
            href={PublicationsService.getPublicationLink(
              {
                category: PublicationCategory.NEWS,
                reference: process.env.REACT_WHERE_TO_SPEND_ARTICLE_REFERENCE,
                title: process.env.REACT_WHERE_TO_SPEND_ARTICLE_TITLE,
              },
              t
            )}
          >
            <a>
              <span>{t("footer:link.spend")}</span>
            </a>
          </Link>
        </li>
        <li>
          <Link href={LinksProvider.ROUTES.FAQ}>
            <a>
              <span>{t("footer:link.faq")}</span>
            </a>
          </Link>
        </li>

        <li>
          <Link href={LinksProvider.ROUTES.CGU}>
            <a>
              <span>{t("footer:link.cgu")}</span>
            </a>
          </Link>
        </li>

        <li>
          <a href="/static/pdf/pvp_gl_mariage_nov_2024.pdf" target="_blank">
            <span>{t("footer:link.rgpd")}</span>
          </a>
        </li>
        <li>
          <a href={"mailto:contact@milleetunelistes.fr"}>
            <span>{t("footer:link.contact")}</span>
          </a>
        </li>

        <li>
          <a href={null} onClick={() => setShowManageCookieModal(true)}>
            <span>{t("footer:link.cookies")}</span>
          </a>
        </li>

        <li>
          <a href="tel:+33805531001">
            <Image
              src={"/static/images/footer/phone.webp"}
              width={233}
              height={29}

            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default withTranslation(namespacesRequired)(FooterSitemap);
